import * as integrationSettingMigrators from '@/services/integrations/migrators';
import * as IntegrationLibrary from '@om/integrations';
import * as BindingHelper from '@/helpers/integration/binding';
import { getVariantRequiredFields } from '@om/integrations';
import { FLOW_STATES } from '@/helpers/integration/newIntegrationFlow';
import campaignFieldsMixin from '@/mixins/integration/campaignFields';

const fixFieldScheme = () => {
  return {
    isFix: true,
    fixValue: '',
    externalId: '',
    externalType: null,
  };
};

export default {
  mixins: [campaignFieldsMixin],

  props: {
    settings: {
      type: Object,
      required: true,
    },
    state: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      checkboxValue: false,
      fields: [],
      defaultCampaignDataFields: [
        { fieldId: 'campaign_name', name: this.$t('campaignName'), type: 'text', externalId: null },
        {
          fieldId: 'url',
          name: this.$t('integrationFlow.integrationModal.mapping.campaignFillUrl'),
          type: 'text',
          externalId: null,
        },
        {
          fieldId: 'variant_name',
          name: this.$t('integrationFlow.integrationModal.mapping.variantName'),
          type: 'text',
          externalId: null,
        },
      ],
      missingRequiredFields: [],
    };
  },

  watch: {
    checkboxValue(value) {
      if (!value) {
        this.deleteAllAdditionalFields();
      }
    },
  },

  computed: {
    formBindings() {
      const notCampaignFieldFilter = (binding) =>
        BindingHelper.CAMPAIGN_FIELD_IDS.includes(binding.fieldId) === false;
      const notFixFieldFilter = (binding) => !binding.isFix;

      let formFields = this.settings.bindings || [];
      formFields = formFields.filter(notFixFieldFilter).filter(notCampaignFieldFilter);

      return formFields.sort((binding1, binding2) => (binding1.name > binding2.name ? 1 : -1));
    },

    staticBindings() {
      return this.settings.bindings?.filter((item) => item.isFix) || [];
    },

    campaignDataBindings() {
      return this.settings.bindings?.filter(
        (binding) => !binding.isFix && BindingHelper.CAMPAIGN_FIELD_IDS.includes(binding.fieldId),
      );
    },

    hasAdditionalData() {
      const hasAdditionalFieldValue = BindingHelper.hasUrlOrCampaignName(this.settings.bindings);

      let hasStaticBindings = !!this.staticBindings.length;
      if (this.settings.type === 'highLevel') {
        const staticBindingsThatAreNotTags = this.staticBindings.filter(
          (binding) => binding.externalType !== '__TAG__',
        );
        hasStaticBindings = !!staticBindingsThatAreNotTags.length;
      }

      return hasStaticBindings || hasAdditionalFieldValue.length;
    },

    bindingErrorMessage() {
      return `${this.$t(
        'integrationBindingErrorMessages.missingRequiredFieldAtLeast',
      )}${this.missingRequiredFields.map((field) => this.$t(field)).join(', ')}`;
    },
  },

  async mounted() {
    this.fields = this.fields.sort((field1, field2) => (field1.value < field2.value ? -1 : 1));

    await this.prepareBindingFields();

    if (this.hasAdditionalData) {
      this.checkboxValue = true;
    }
  },

  methods: {
    async prepareBindingFields() {
      const campaignFields = await this.fetchCampaignFields();

      const campaignFieldsAsBinding = BindingHelper.convertCampaignFieldsToBinding(campaignFields);

      let bindings = campaignFieldsAsBinding.sort((field1, field2) =>
        field1.name < field2.name ? -1 : 1,
      );

      bindings = this.mergeExistingBindings(bindings, this.settings.bindings);

      bindings = this.migrateBindings(this.settings.type, bindings);

      bindings = this.setIntegrationBindingSuggestions(
        this.settings.type,
        bindings,
        campaignFields,
      );

      this.updateBindings(bindings);
    },

    mergeExistingBindings(campaignBindings, existingBindings) {
      if (!existingBindings) {
        return campaignBindings;
      }

      const bindings = [...campaignBindings, ...this.defaultCampaignDataFields];

      bindings.forEach((binding, index) => {
        const existingBinding = existingBindings.find(
          (existing) => existing.fieldId === binding.fieldId,
        );

        if (!existingBinding) {
          return;
        }

        bindings[index].externalId = existingBinding.externalId;
      });

      const fixFields = existingBindings.filter((binding) => binding.isFix);

      return [...bindings, ...fixFields];
    },

    migrateBindings(integrationType, bindings) {
      if (this.state !== FLOW_STATES.EDIT) {
        return bindings;
      }

      const migrator = integrationSettingMigrators.default[integrationType];

      if (migrator) {
        migrator(this.settings, bindings, this.brandName);
      }

      return bindings;
    },

    setIntegrationBindingSuggestions(integrationType, bindings, campaignFields) {
      const suggestions = IntegrationLibrary.createBinding(integrationType, campaignFields);

      for (const suggestion of suggestions) {
        const bindingIndex = bindings.findIndex(
          (binding) => binding.fieldId === suggestion.fieldId,
        );

        if (!bindings[bindingIndex].externalId) {
          bindings[bindingIndex].externalId = suggestion.externalId;
        }
      }

      return bindings;
    },

    updateBindings(bindings) {
      this.$emit('update:settings', {
        ...this.settings,
        bindings,
      });
    },

    addStaticField() {
      const bindings = [...this.settings.bindings, fixFieldScheme()];
      this.updateBindings(bindings);
    },

    deleteStaticField(index) {
      const nonRemovableBindingsLength =
        this.formBindings.length + this.campaignDataBindings.length;
      const bindings = this.settings.bindings;

      bindings.splice(nonRemovableBindingsLength + index, 1);

      this.updateBindings(bindings);
    },

    resetCampaignFields() {
      return [...this.campaignDataBindings].map((field) => {
        return {
          ...field,
          externalId: null,
        };
      });
    },
    deleteAllAdditionalFields() {
      const campaignDataBindings = this.resetCampaignFields();

      let bindings = [...this.formBindings, ...campaignDataBindings];
      if (this.settings.type === 'highLevel') {
        bindings = [
          ...bindings,
          ...this.staticBindings.filter((binding) => binding.externalType === '__TAG__'),
        ];
      }
      this.updateBindings(bindings);
    },

    cleanUpBindings() {
      const bindings = this.settings.bindings.filter((binding) => {
        const isValidStatic = binding.isFix && binding.fixValue.length && binding.externalId;
        return binding.isFix ? isValidStatic : binding.externalId;
      });

      this.updateBindings(bindings);
    },

    validateBindings() {
      this.missingRequiredFields = [];
      const requiredFields = getVariantRequiredFields(this.settings.type);

      if (!requiredFields) return true;

      let hasAtLeastOneRequiredField = false;
      const missingRequiredFields = [];

      for (const requiredField of requiredFields) {
        const binding = this.formBindings.find(({ type }) => type === requiredField);
        if (binding?.externalId) {
          hasAtLeastOneRequiredField = true;
        } else {
          missingRequiredFields.push(requiredField);
        }
      }

      if (hasAtLeastOneRequiredField) {
        return true;
      }

      this.missingRequiredFields = missingRequiredFields;

      return false;
    },

    bindingRowHasError(binding) {
      return this.missingRequiredFields.includes(binding.type);
    },

    getStaticField() {
      return fixFieldScheme();
    },
  },
};
