<template lang="pug">
.general-step(:class="{ active }" @click="$emit('stepClick', step)")
  .wrapper
    .d-flex.step-header
      component(:is="icon.component" :size="icon.size")
      .title {{ title }}
    .progress-bar.mr-1
</template>
<script>
  export default {
    props: {
      step: {
        type: String,
        required: true,
      },
      active: {
        type: Boolean,
        required: true,
      },
      icon: {
        type: Object,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
    },
  };
</script>
