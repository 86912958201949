class AbstractIntegrationSettings {
  constructor() {
    this.type = '';
  }

  setFromSettings(settings) {
    Object.keys(settings).forEach((setting) => {
      this[setting] = settings[setting];
    });
  }

  // eslint-disable-next-line no-unused-vars
  setFromExisting(data) {
    throw Error('Not implemented');
  }

  getGlobals() {
    throw Error('Not implemented');
  }

  getCampaignSettings() {
    throw Error('Not implemented');
  }
}

export { AbstractIntegrationSettings };
