import { AbstractIntegrationSettings } from '@/services/integrations/settings/AbstractIntegrationSettings';

class ActiveCampaign extends AbstractIntegrationSettings {
  constructor() {
    super();
    this.type = 'activeCampaign';
  }

  setFromExisting(integrationData) {
    this.id = integrationData?.id || '';
    this.apiUrl = integrationData?.global?.data?.apiUrl || '';
    this.apiKey = integrationData?.global?.data?.apiKey || '';
    this.name = integrationData?.global?.data?.name || '';
    this.campaignIntegrationId = integrationData._id || '';

    this.settings = integrationData?.settings || [];
    this.bindings = integrationData?.bindings || [];

    this.convertedSettings = this.settings.reduce((previousObject, setting) => {
      return {
        [setting.key]: setting.value,
        ...previousObject,
      };
    }, integrationData?.convertedSettings || {});
  }

  getGlobals() {
    return {
      apiUrl: this.apiUrl,
      apiKey: this.apiKey,
      name: this.name,
    };
  }

  getCampaignSettings() {
    const settings = [];
    Object.keys(this.convertedSettings).forEach((key) => {
      settings.push({ key, value: this.convertedSettings[key] });
    });

    return {
      settings,
      bindings: this.bindings,
    };
  }
}

export { ActiveCampaign };
