const CAMPAIGN_FIELD_IDS = ['url', 'campaign_name', 'variant_name'];

const convertCampaignFieldsToBinding = (campaignFields) => {
  return campaignFields.map((f) => {
    return {
      fieldId: f.customId,
      name: f.name,
      type: f.type,
      externalId: null,
    };
  });
};

const hasUrlOrCampaignName = (bindings) => {
  return bindings.filter(
    (field) => CAMPAIGN_FIELD_IDS.includes(field.fieldId) && field.externalId?.length > 0,
  );
};

export { CAMPAIGN_FIELD_IDS, convertCampaignFieldsToBinding, hasUrlOrCampaignName };
