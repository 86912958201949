<template lang="pug">
.d-flex.stepper-wrapper
  .steps(v-for="(step, index) in steps")
    step(
      :step="step"
      :active="isStepEarlierThanActual(step)"
      :icon="meta[step].icon"
      :title="meta[step].title"
      @stepClick="$emit('stepClick', step)"
      :key="index"
    )
</template>
<script>
  import { UilCog, UilListUl, UilArrowToRight } from '@iconscout/vue-unicons';
  import Step from './step.vue';

  export default {
    components: {
      Step,
      UilCog,
      UilListUl,
      UilArrowToRight,
    },

    props: {
      activeStep: {
        type: String,
      },

      steps: {
        type: Array,
        default: () => ['setup', 'listId', 'fieldMapping'],
      },
    },

    data() {
      return {
        meta: {
          setup: {
            active: true,
            icon: {
              component: UilCog,
              size: '1.5rem',
            },
            title: this.$t('integrationFlow.integrationModal.setupIntegration'),
          },
          listId: {
            active: false,
            icon: {
              component: UilListUl,
              size: '1.5rem',
            },
            title: this.$t('integrationFlow.integrationModal.selectAList'),
          },
          fieldMapping: {
            active: false,
            icon: {
              component: UilArrowToRight,
              size: '1.5rem',
            },
            title: this.$t('integrationFlow.integrationModal.fieldMapping'),
          },
        },
      };
    },

    methods: {
      isStepEarlierThanActual(step) {
        const actualStepIndex = this.steps.indexOf(this.activeStep);
        const stepIndex = this.steps.indexOf(step);

        return stepIndex <= actualStepIndex;
      },
    },
  };
</script>
