import UPDATE_CAMPAIGN_INTEGRATION from '@/graphql/UpdateCampaignIntegration.gql';
import ADD_CAMPAIGN_INTEGRATION from '@/graphql/AddCampaignIntegration.gql';

class CampaignIntegrationService {
  constructor(apollo) {
    this.$apollo = apollo;
  }

  async saveIntegration(globalIntegrationId, campaignId, campaignIntegrationId, data) {
    let isSuccess;
    let validationText;
    let integrationId;

    if (campaignIntegrationId) {
      // existing integration update
      integrationId = campaignIntegrationId;
      isSuccess = await this.updateCampaignIntegration(
        campaignId,
        campaignIntegrationId,
        globalIntegrationId,
        data,
      );

      validationText = isSuccess ? 'saveSuccess' : 'saveError';
    } else {
      try {
        integrationId = await this.addCampaignIntegration(campaignId, globalIntegrationId, data);
        isSuccess = true;
      } catch (e) {
        console.error(e.message);
      }

      validationText = isSuccess ? 'addSuccess' : 'addError';
    }

    return {
      success: isSuccess,
      validationText,
      integrationId,
    };
  }

  async addCampaignIntegration(campaignId, globalIntegrationId, data) {
    const { bindings, settings } = data;

    const {
      data: { addCampaignIntegration },
    } = await this.$apollo.mutate({
      mutation: ADD_CAMPAIGN_INTEGRATION,
      variables: {
        campaignId,
        input: {
          id: globalIntegrationId,
          bindings,
          settings,
        },
      },
    });

    return addCampaignIntegration;
  }

  async updateCampaignIntegration(campaignId, integrationId, globalIntegrationId, data) {
    const { bindings, settings } = data;

    const {
      data: { updateCampaignIntegration },
    } = await this.$apollo.mutate({
      mutation: UPDATE_CAMPAIGN_INTEGRATION,
      variables: {
        campaignId,
        input: {
          _id: integrationId,
          id: globalIntegrationId,
          settings,
          bindings,
        },
      },
    });

    return updateCampaignIntegration;
  }
}

export { CampaignIntegrationService };
