import { IntegrationService } from '@/services/integrations/integrationService';
import { IntegrationSettingsFactory } from '@/services/integrations/IntegrationSettingsFactory';
import { CampaignIntegrationService } from '@/services/integrations/CampaignIntegrationService';
import { FLOW_STATES } from '@/helpers/integration/newIntegrationFlow';
import { removeErrorQueryParams } from '@/components/IntegrationModals/utils';

export default {
  props: {
    state: {
      type: String,
    },
    integrationData: {
      type: Object,
      default: () => {},
    },
    failedIntegrationResyncService: {
      type: Object,
      default: null,
    },
    alertCode: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      integrationService: null,
      integrationSettings: null,
      steps: ['setup', 'listId', 'fieldMapping'],
      isValid: false,
      validByAlert: {
        apiKey: true,
        list: true,
        fields: true,
        apiKeyFixed: false,
      },
      loading: true,
      settings: {}, // needed for vuelidate behavior
    };
  },

  watch: {
    '$v.$invalid': {
      handler(val) {
        this.isValid = !val;
        this.$bus.$emit('form-is-valid', !val);
      },
      deep: true,
    },
    'settings.apiKey': {
      handler() {
        this.validByAlert.apiKey = true;
      },
      deep: true,
    },
  },

  computed: {
    isFirstStep() {
      return this.steps.indexOf(this.activeContent) === 0;
    },

    alignedSteps() {
      const steps = [...this.steps];

      return steps;
    },
  },

  beforeMount() {
    this.integrationService = new IntegrationService(this.$apollo);
    this.campaignIntegrationService = new CampaignIntegrationService(this.$apollo);

    const settings = this.createSettings();
    settings.setFromExisting(this.integrationData);
    this.settings = settings;
  },

  mounted() {
    this.$v.$reset();

    if (!this.alertCode && this.state !== FLOW_STATES.NEW) {
      this.activeContent = this.steps[1];
    }

    if (this.alertCode) {
      this.handleAlertCode();
    }

    this.$bus.$on('integration-show-first-step', () => {
      this.activeContent = this.steps[0];
    });

    this.loading = false;
  },

  beforeDestroy() {
    this.$bus.$off('integration-show-first-step');
  },

  methods: {
    createSettings() {
      return IntegrationSettingsFactory.create(this.integrationType);
    },

    async validate() {
      const validations = this.$v.settings;

      validations.$touch();

      if (validations.$pending || validations.$error) {
        this.isValid = false;
        return false;
      }

      if (this.isFirstStep) {
        const success = await this.handleFirstStep();
        if (!success) return;

        this.$bus.$emit('fetchIntegrations');
      }

      this.$bus.$emit('form-is-valid', true);
      this.isValid = true;
      return true;
    },

    async validateClickedStep(step) {
      const isValid = await this.validate();

      if (!isValid) return false;

      this.activeContent = step;
      return true;
    },

    async handleFirstStep() {
      /** New instance required because the settings override in steps
       * could cause function lost
       *
       * Example use case:
       * While adding new integration creating global data typed
       * then going to next step and selecting a list
       * and after that gong back to the first step and
       * getGlobal for Klaviyo stop's working
       */

      const settings = this.createSettings();
      settings.setFromSettings(this.settings);

      if (this.state === FLOW_STATES.NEW && !settings.id) {
        const isCreated = await this.createGlobalIntegration(settings);
        return !!isCreated;
      }

      if (!settings.id) return;

      const updated = await this.updateExistingGlobalIntegration(
        settings.id,
        settings.type,
        settings.getGlobals(),
      );
      return !!updated;
    },

    async handleButtonClick(type) {
      const currentStepIndex = this.steps.indexOf(this.activeContent);

      if (type === 'back') {
        this.activeContent = this.steps[currentStepIndex - 1];
      } else if (type === 'done') {
        const isValid = this.$refs.bindings.validateBindings();

        if (!isValid) {
          const { missingRequiredFields } = this.$refs.bindings;
          if (missingRequiredFields) {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.newIntegrationFlow.missingRequiredFields'),
            });
          }
          return;
        }

        this.$refs.bindings.cleanUpBindings();

        const campaignId = parseInt(this.$route.params.id, 10);

        const settings = this.createSettings();
        settings.setFromSettings(this.settings);

        const result = await this.campaignIntegrationService.saveIntegration(
          settings.id,
          campaignId,
          settings.campaignIntegrationId,
          settings.getCampaignSettings(),
        );

        this.notifyIntegrationOperation(result.success, result.validationText);

        if (result.success) {
          settings.campaignIntegrationId = result.integrationId;
          this.$modal.hide('integration-new-flow');
          this.$bus.$emit('fetchIntegrations', settings);
        }

        if (!this.failedIntegrationResyncService) return;

        this.$modal.show('lead-sync-modal', {
          totalLeads: this.failedIntegrationResyncService.getErrorCount(),
          campaignId: this.$route.params.id,
          serviceInstance: this.failedIntegrationResyncService,
          newIntegrationData: settings,
        });
      } else if (type === 'next') {
        const isValid = await this.validate();
        if (isValid) {
          this.activeContent = this.steps[currentStepIndex + 1];
        }
      } else if (type === 'cancel') {
        const queryParams = removeErrorQueryParams(this.$route.query);
        this.$router.replace({ queryParams });
        this.$modal.hide('integration-new-flow');
      }
    },

    notifyIntegrationOperation(isSuccess, text) {
      if (!text) return;

      if (!isSuccess) {
        this.$notify({
          type: 'error',
          text: this.$t(`notifications.${text}`),
        });
        return;
      }

      this.$notify({
        type: 'success',
        text: this.$t(`notifications.${text}`),
      });
    },

    async createGlobalIntegration(settings) {
      try {
        const result = await this.integrationService.addIntegration(
          settings.type,
          settings.getGlobals(),
        );
        if (result.success) {
          settings.id = result.id;
          this.settings = settings;

          this.$notify({
            type: 'success',
            text: this.$t('notifications.addSuccess'),
          });
          return true;
        }
      } catch (error) {
        console.error('Error during adding integration', error.message);
      }

      this.$notify({
        type: 'error',
        text: this.$t('notifications.addError'),
      });
      return false;
    },

    async updateExistingGlobalIntegration(integrationId, integrationType, data) {
      try {
        const result = await this.integrationService.updateExistingIntegration(
          integrationId,
          integrationType,
          data,
        );
        if (result.success) {
          if (this.alertCode) {
            setTimeout(() => {
              this.validByAlert.apiKeyFixed = true;
            }, 300);
          } else {
            this.$notify({
              type: 'success',
              text: this.$t('notifications.editSuccess'),
            });
          }
          return true;
        }
      } catch (error) {
        console.error('Error during update existing integration', error.message);
      }

      if (!this.alertCode) {
        this.$notify({
          type: 'error',
          text: this.$t('notifications.editError'),
        });
      }

      this.validByAlert.apiKeyFixed = false;
      this.validByAlert.apiKey = false;

      return false;
    },
    handleAlertCode() {
      this.activeContent = this.determineActiveContent();
      if (this.alertCode === 'IntegrationErrorList') {
        this.validByAlert.list = false;
      } else if (this.alertCode === 'IntegrationErrorField') {
        this.validByAlert.fields = false;
      } else {
        this.validate();
      }
    },
    determineActiveContent() {
      let step = 'setup';

      if (this.steps?.length === 2) {
        step = this.alertCode.includes('APIKey') ? step : 'fieldMapping';
      } else if (this.alertCode === 'IntegrationErrorList') {
        step = 'listId';
      } else if (this.alertCode === 'IntegrationErrorField') {
        step = 'fieldMapping';
      }

      return step;
    },
  },
};
