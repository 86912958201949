import ALL_FIELDS from '@/graphql/AllFields.gql';

export default {
  methods: {
    async fetchCampaignFields() {
      try {
        const {
          data: { allFields },
        } = await this.$apollo.query({
          query: ALL_FIELDS,
          variables: {
            campaignId: parseInt(this.$route.params.id, 10),
          },
        });

        return allFields;
      } catch (e) {
        console.error('Error during fetch campaign fields', e.message);
        return [];
      }
    },
  },
};
