import GET_INTEGRATION_DATA from '@/graphql/GetIntegrationData.gql';
import GET_INTEGRATION_LISTS from '@/graphql/GetIntegrationLists.gql';
import GET_INTEGRATION_FIELDS from '@/graphql/GetIntegrationFields.gql';
import ADD_GLOBAL_INTEGRATION from '@/graphql/AddGlobalIntegration.gql';
import EDIT_GLOBAL_INTEGRATION from '@/graphql/EditGlobalIntegration.gql';

class IntegrationService {
  constructor(apollo) {
    this.$apollo = apollo;
  }

  async addIntegration(integrationType, data) {
    try {
      const result = await this.$apollo.mutate({
        mutation: ADD_GLOBAL_INTEGRATION,
        variables: {
          input: {
            type: integrationType,
            data,
          },
        },
      });

      if (result?.data?.addIntegration?.success) {
        return result.data.addIntegration;
      }
    } catch (e) {
      console.error('Error while adding integration', e.message);
    }

    return { success: false };
  }

  async updateExistingIntegration(globalIntegrationId, integrationType, data) {
    let result;

    try {
      result = await this.$apollo.mutate({
        mutation: EDIT_GLOBAL_INTEGRATION,
        variables: {
          integrationId: globalIntegrationId,
          input: {
            type: integrationType,
            data,
          },
        },
      });
    } catch (e) {
      console.error('Error while updating integration', e.message);
    }

    return { success: result.data?.editIntegration?.success || false };
  }

  async fetchIntegrationData(integrationType, integrationId) {
    try {
      const result = await this.$apollo.query({
        query: GET_INTEGRATION_DATA,
        variables: {
          integrationType,
          integrationId,
        },
      });

      return result?.data?.integrationData;
    } catch (e) {
      console.error('Integration data query failed', e.message);
      throw new Error(e.message);
    }
  }

  async fetchIntegrationLists(integrationType, integrationId) {
    try {
      const result = await this.$apollo.query({
        query: GET_INTEGRATION_LISTS,
        variables: {
          integrationType,
          integrationId,
        },
      });

      return result?.data;
    } catch (e) {
      console.error('Integration data query failed', e.message);
      throw Error(e.message);
    }
  }

  async fetchIntegrationFields(integrationType, integrationId, listId) {
    try {
      const result = await this.$apollo.query({
        query: GET_INTEGRATION_FIELDS,
        variables: {
          integrationType,
          integrationId,
          listId,
        },
      });

      return result?.data?.fields;
    } catch (e) {
      console.error('Integration fields query failed', e.message);
      throw new Error(e.message);
    }
  }
}

export { IntegrationService };
